<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import FormCategory from "@/components/forms/form-category/index";

import {
    categoryMethods,
    notificationMethods,
} from "@/state/helpers";

/**
 * Category Form component
 */
export default {
  page: {
    title: "Cadastrar Categoria",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, FormCategory },
  data() {
    return {
      title: "Cadastrar Categoria",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Categorias",
          href: "/categories",
        },
        {
          text: "Cadastrar Categoria",
          active: true,
        },
      ], 
      editingCategory: {
        title: "",
      },
      loading: false,
    };
  },
  props: ['id'],
  created() {
    if(this.id) {
      this.getById();
    }
  },
  methods: {
    ...categoryMethods,
    ...notificationMethods,
    async getById() {
      this.loading = true; 
      const category = await this.getCategory(this.id);
      setTimeout(() => {
        this.editingCategory = category?.data;        
      }, 200);
      this.loading = false;
    },

    handleNewCad() {      
      this.$router.go(-1);
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :enableCad="false" />
    <div class="row justify-content-center">      
      <div class="col-xl-8">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-3">Formulário de {{ id ? 'Edição' : 'Cadastro' }} de Categoria</h4>
            <b-spinner
              v-if="loading"
              label="Spinning"
              variant="info"
              class="m-1"
            ></b-spinner>
            <FormCategory              
              v-if="!loading"
              :parent="this"
              :editingCategory="editingCategory"
              @newCad="handleNewCad"
              @newCadSuccess="handleNewCad"
            ></FormCategory>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
